import { KilnTypeUIRenderer } from "./factory";
import Text from "antd/es/typography/Text";
import { CloseSquareTwoTone, CheckCircleTwoTone, QuestionCircleTwoTone, BarChartOutlined } from '@ant-design/icons';
import { Popover, Space, Tag } from "antd";
import { COLOUR_FAILURE, COLOUR_SUCCESS, COLOUR_UNDEFINED, KilnType, genAnalysisChartPanel, genBooleanDisplay } from "./common";
import { ReactNode } from "react";
import { largeValPrettify, msToDuration, numberAsBool } from "../common";

export class CGoOneUIRenderer implements KilnTypeUIRenderer {

    genKilnTypeTag() : ReactNode {
        return (<Tag color='green' key={KilnType.CGoOne}>C-Go-1</Tag>);
    }

    genKilnValidationWidget(detail: any, valid: number|null) : ReactNode {
        if (!detail) {
            return (<QuestionCircleTwoTone twoToneColor={COLOUR_UNDEFINED}/>)
        }
        if (numberAsBool(valid)) {
            return (<CheckCircleTwoTone twoToneColor={COLOUR_SUCCESS} />)
        }
        else {
            return (<CloseSquareTwoTone twoToneColor={COLOUR_FAILURE} />)
        }
    }

    genAnalysisDetailPanel(json: any) : ReactNode {
        return (
            <Space direction="vertical" size="small">
                <div><Text type="secondary">Zero Weight Used: </Text><Text>{largeValPrettify(json.zero_weight, 2)}</Text></div>
                <div><Text type="secondary">Per Unit Weight Used: </Text><Text>{largeValPrettify(json.per_unit_weight, 4)}</Text></div>
                <div><Text type="secondary">Burn Duration: </Text><Text>{msToDuration(json.burn_duration)}</Text></div>
                <div><Text type="secondary">Charing Rate: </Text><Text>{largeValPrettify(json.charing_rate, 2)}kg/m</Text></div>
                <div><Text type="secondary">Loading Rate: </Text><Text>{largeValPrettify(json.loading_rate, 2)}kg/m</Text></div>
                <div><Text type="secondary">Biomass Loaded: </Text><Text>{largeValPrettify(json.biomass_loaded, 2)}kg</Text></div>
                <div><Text type="secondary">Biomass Pre-Quench: </Text><Text>{largeValPrettify(json.biochar_prior_to_quench, 2)}kg</Text></div>
                <div><Text type="secondary">Unpyrolyzed Biomass: </Text><Text>{largeValPrettify(json.unpyrolized_biomass, 2)}kg</Text></div>
                <div><Text type="secondary">Biochar Produced: </Text><Text>{largeValPrettify(json.biochar_produced, 2)}kg</Text></div>
                <div><Text type="secondary">CO2 Removed: </Text><Text>{largeValPrettify(json.co2_removed, 2)}kg</Text></div>
            </Space>
            );
    }

    genAnalysisDetailOrErrorIndicator(unparsedChartData : string, unparsedDetail: any|null, error: string|null, chartClickHandler: (event: any) => void) : ReactNode {
        if (error && error.length > 0) {
            return (
                <>
                    <Popover content={<Text>{error}</Text>}>
                        {genBooleanDisplay(false)}
                    </Popover>
                    <Popover content={genAnalysisChartPanel(unparsedChartData, chartClickHandler)}>
                        <BarChartOutlined />
                    </Popover>
                </>
            ); 
        }
        else if (unparsedDetail && unparsedDetail.length > 3) {
            const vDetail: any = JSON.parse(unparsedDetail);
            return (
              <>
                <Popover content={this.genAnalysisDetailPanel(vDetail)}>
                    {genBooleanDisplay(vDetail.valid && vDetail.valid === 'true')}
                </Popover>
                <Popover content={genAnalysisChartPanel(unparsedChartData, chartClickHandler)}>
                    <BarChartOutlined />
                </Popover>
              </>
              );
          }
        return (<></>);
    }
}