import { Button, Table, Space, Form, Input, DatePickerProps, InputNumber, DatePicker, Select, Collapse, Modal, notification, Popover } from "antd";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { ERROR, SUCCESS, updateFormValue } from "./common";
import { KilnType } from "../kiln_types/common";
import Text from "antd/es/typography/Text";
import { KilnTypeUIRenderer, genRendererMap } from "../kiln_types/factory";
import { Can } from "../../permissions";

function KilnRegistryTable(props: any) {
  const cols : ColumnsType<any>= [
    {
      title: 'Kiln ID',
      dataIndex: 'kiln_id',
      key: 'kiln_id',
      render: (_: any, record:any) => (
        <a href={`/kiln_detail?id=${record.kiln_id}`}>{record.kiln_id}</a>
      )
    },
    {
      title: 'Facility',
      dataIndex: 'facility_mnemonic',
      key: 'facility_mnemonic',
      render: (_: any, record:any) => (
        <Popover content={(<Text>{record.facility_name}</Text>)} ><Text>{record.facility_mnemonic}</Text></Popover>
      )
    },      
    {
      title: 'Kiln Type',
      dataIndex: 'type',
      key: 'type',
      render: (_: any, record: any) => {
        const renderer : KilnTypeUIRenderer = props.rendererMap.get(record.type as KilnType)
        return renderer.genKilnTypeTag();
      }
    },
    {
      title: 'Install Date',
      dataIndex: 'install_date',
      key: 'install_date',
    },
    {
      title: 'Last Update',
      dataIndex: 'last_update',
      key: 'last_update',
    },
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      width: 100,
      render: (_:any, record: any) => {
        return (
          <>
            <Can I='update' a='kiln'>
              <Button type="dashed" onClick={() => props.editFunction(record)}>Edit</Button>
            </Can>
          </>
        )
      }
    },
  ];
    
  return (
    <Table dataSource={props.kilns} columns={cols} />
  )
}

function KilnRegistry(props : any) {

  const defaultKilnFormContent: any = {
    edit: false,
    id: null,
    kiln_id: null,
    kiln_id_status: ERROR,
    facility_id: null,
    facility_id_status: ERROR,
    kiln_type: 0,
    install_date: null,
    install_date_status: ERROR,
    config: {
      cgo1: {
        per_g_weight: null,
        zero_weight: null,
      },
    },
    allValid: false,
  };

  const dateFormat = 'YYYY/MM/DD'
  const [kilnFormContent, setKilnFormContent] = useState<any>(defaultKilnFormContent);
  const [kilns, setKilns] = useState<any[]>([]);
  const [facilities, setFacilities] = useState<any[]>([]);
  const [kilnFacilityIndex, setKilnFacilityIndex] = useState<any>({});
  const [showKilnEdit, setShowKilnEdit] = useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [searchParams, setSearchParams] = useState<any>({
    kilnId: "",
    trigger: 0,
  });
  const kilnTypeRenderMap: Map<KilnType, KilnTypeUIRenderer> = genRendererMap();

  useEffect(() => {
    executeSearch();
  }, [searchParams])

  const executeSearch = async () => {
    await fetch('/api/kiln/registry/list', {
      headers: {
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        },
    }).then(response => {
      if (response.ok) {
        return response.json();
      }
      notification.error({
        message: 'Error retrieving kiln registry.',
        description: 'An unknown error occurred retrieving the kiln registry. Please try again later.',
        duration: 4,
        placement: "top",
      });
      return null;
    })
      .then(data => {
        if (data !== null) {
          setKilns(data.kilns);
          setFacilities(data.facilities);
          setKilnFacilityIndex(Object.assign({}, ...data.kilns.map((k: any) => ({[`${k.kiln_id}:${k.facility_id}`]: true}))));
        }
      })
      .catch(err => {
        console.log("error " + err);
      });
  }

  // a hack to cause a change to the search data, so a re-search is triggered to populate
  // the displayed table.
  function refresh(): void {
    setSearchParams({
      ...searchParams, 
      trigger: searchParams.trigger + 1});
  }

  const handleOk = () => {
    setConfirmLoading(true);
    const requestOptions = {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
      },
      body: JSON.stringify({
        id: kilnFormContent.id,
        kiln_id: kilnFormContent.kiln_id,
        facility_id: kilnFormContent.facility_id,
        type: kilnFormContent.kiln_type,
        install_date: kilnFormContent.install_date,
        config: kilnFormContent.config,
      }),
    };
    fetch("/api/kiln/registry/" + (kilnFormContent.edit ? "edit" : "add"), requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 400) {
          notification.error({
            message: 'Error persisting kiln.',
            description: 'Bad request, please check your inputs.',
            duration: 4,
            placement: "top",
          });
          setConfirmLoading(false);
          return null;
        }
        else {
          notification.error({
            message: 'Error persisting kiln.',
            description: 'Unknown server error. Try again later or contact support.',
            duration: 4,
            placement: "top",
          });
          closeModal();
        }
      })
      .then(data => {
        if (data !== null && data.status === 1) {
          closeModal();
          refresh();
        }
      })
      .catch(err => {
        console.log("Uncaught error! " + err);
        closeModal();
      });
  };

  const closeModal = () => {
    setConfirmLoading(false);
    setShowKilnEdit(false);
  };

  /**
   * Handle Cancel - Form hide again
   */
  const handleCancel = () => {
    closeModal();
  };

  const showFormInEditMode = (record: any) => {
    const config: any = JSON.parse(record.config);
    setKilnFormContent({
      edit: true,
      id: record.id,
      kiln_id: record.kiln_id,
      kiln_id_status: SUCCESS,
      kiln_type: record.type,
      facility_id: record.facility_id,
      facility_id_status: SUCCESS, 
      install_date: dayjs(record.install_date),
      install_date_status: SUCCESS,
      config: config,
      allValid: true,
    });
    setShowKilnEdit(true);
  };

  const handleKilnIdChange = (e:any) => {
    const isErrored = e.target.value === null || e.target.value.length === 0 || kilnFacilityIndex[`${e.target.value}:${kilnFormContent.facility_id}`]
    updateFormValue(setKilnFormContent, kilnFormContent, "kiln_id",e.target.value, isErrored ? ERROR : SUCCESS, "facility_id_status");
  }

  const handleInstallDateChange:  DatePickerProps['onChange'] = (date: dayjs.Dayjs|null, dateString: string | string[]) => {
    updateFormValue(setKilnFormContent, kilnFormContent, "install_date", date, date !== null ? SUCCESS : ERROR);
  }

  // weight handling
  const handleCGoOneZeroWeightChange = (value: number|null) => {
    const oldObj:any =  kilnFormContent.config;
    oldObj.cgo1 ??= {};
    oldObj.cgo1.zero_weight = value;

    setKilnFormContent({
      ...kilnFormContent,
      "config": oldObj,
    });
  }

  const handleCGoOnePerGWeightChange = (value: number|null) => {
    const oldObj:any =  kilnFormContent.config;
    oldObj.cgo1 ??= {};
    oldObj.cgo1.per_g_weight = value;

    setKilnFormContent({
      ...kilnFormContent,
      "config": oldObj,
    });  
  }

  const handleTypeChange = (value: string) => {
    updateFormValue(setKilnFormContent, kilnFormContent, "kiln_type", value, value != null ? SUCCESS : ERROR);
  };

  const handleFacilityChange = (value: string) => {
    const isErrored = value === null || kilnFacilityIndex[`${kilnFormContent.kiln_id}:${value}`]
    updateFormValue(setKilnFormContent, kilnFormContent, "facility_id", value, isErrored ? ERROR : SUCCESS, "kiln_id_status");
  }

  // type specific options form elements here
  const kilnTypeConfigurationOptions = [
    {
      key: "1",
      label: "C-Go-1 Kiln Type Configuration",
      children: <>
        <Form.Item label="Per g Weight" required={false} tooltip="Sensor value reported per g of mass measured.">
          <InputNumber
              min={0}
              max={100000}
              precision={4}
              value={kilnFormContent.config && kilnFormContent.config.cgo1 ? kilnFormContent.config.cgo1.per_g_weight : null}
              onChange={handleCGoOnePerGWeightChange}
            />
        </Form.Item>
        <Form.Item 
          label='Zero Weight' 
          required={false} 
          validateStatus={kilnFormContent.zWeight_status}
          tooltip="Weight reading from the kiln when empty.">
          <InputNumber
            min={-100000}
            max={5000000}
            precision={2}
            value={kilnFormContent.config && kilnFormContent.config.cgo1 ? kilnFormContent.config.cgo1.zero_weight : null}
            onChange={handleCGoOneZeroWeightChange}
          />
        </Form.Item>
      </>,
    },
  ];

  return (
  <div className="wrapper">
    <Space size="middle">
      <h1>Kiln Registry</h1>
      <Can I='create' a='kiln'>
        <Button onClick={() => {
              setKilnFormContent(defaultKilnFormContent);
              setShowKilnEdit(true);
            }
          }>New Kiln</Button>
      </Can>
    </Space>
    <KilnRegistryTable 
      kilns={kilns} 
      editFunction={(rec: any) => showFormInEditMode(rec)} 
      rendererMap={kilnTypeRenderMap}
      refreshCallback={refresh}/>
    <Modal
      title="Kiln"
      open={showKilnEdit}
      onOk={handleOk}
      okButtonProps={{disabled: !kilnFormContent.allValid}}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}>
      <Form labelCol={{ span: 12 }} wrapperCol={{ span: 16 }} style={{ maxWidth: 1000 }}>
        <Form.Item label='Kiln ID' required={true} validateStatus={kilnFormContent.kiln_id_status} tooltip="The unique ID for the kiln - must match that which the kiln identifies with." >
          <Input 
            value={kilnFormContent.kiln_id} 
            onChange={handleKilnIdChange}/>
        </Form.Item>
        <Form.Item 
          label='Type' 
          required={true} 
          tooltip="Type of kiln - this will impact the choice of analysis algorithm used." >
          <Select
            style={{ width: 120 }}
            onChange={handleTypeChange}
            options={[
              { value: KilnType.CGoOne, label: 'C-Go-1' },
              { value: KilnType.Retort, label: 'Retort' },
              { value: KilnType.Terrafix, label: 'Terrafix' },
            ]}
            value={kilnFormContent.kiln_type}
          />
        </Form.Item>
        <Form.Item label='Facility' 
          required={true} 
          validateStatus={kilnFormContent.facility_id_status}
          tooltip='Facility in which the kiln is installed.'>
        <Select
            style={{ width: 120 }}
            onChange={handleFacilityChange}
            options={facilities}
            value={kilnFormContent.facility_id}
          />
        </Form.Item> 
        <Form.Item 
          label='Install Date' 
          required={true} 
          validateStatus={kilnFormContent.install_date_status} 
          tooltip="The install date of the kiln.">
          <DatePicker
            format={dateFormat}
            allowClear={false}
            value={kilnFormContent.install_date}
            onChange={handleInstallDateChange}
          />
        </Form.Item>
        <Collapse accordion items={kilnTypeConfigurationOptions} />
      </Form>
    </Modal>
  </div>
  );
}

export default KilnRegistry;