import { KilnTypeUIRenderer, UnknownKilnTypeRenderer } from "./factory";
import Text from "antd/es/typography/Text";
import { Popover, Space, Tag } from "antd";
import { KilnType, genAnalysisChartPanel, genBooleanDisplay } from "./common";
import { BarChartOutlined } from '@ant-design/icons';
import { ReactNode } from "react";
import { largeValPrettify, msToDuration } from "../common";

export class TerrafixUIRenderer implements KilnTypeUIRenderer {

    readonly delegate : KilnTypeUIRenderer = new UnknownKilnTypeRenderer();

    genKilnTypeTag() : ReactNode {
        return (<Tag color='red' key={KilnType.Terrafix}>Terrafix</Tag>);
    }

    genKilnValidationWidget(detail: any, valid: number|null) : ReactNode {
        return this.delegate.genKilnValidationWidget(detail, valid);
    }

    genAnalysisDetailPanel(json: any) : ReactNode {
        return (
            <Space direction="vertical" size="small">
                <div><Text type="secondary">Burn Duration: </Text><Text>{msToDuration(json.burn_duration)}</Text></div>
                <div><Text type="secondary">Max Temp 1: </Text><Text>{parseFloat(json.max_temp_1).toFixed(2)}&deg;C</Text></div>
                <div><Text type="secondary">Max Temp 2: </Text><Text>{parseFloat(json.max_temp_2).toFixed(2)}&deg;C</Text></div>
                <div><Text type="secondary">Mass Removed: </Text><Text>{largeValPrettify(json.weight_removed_sum, 2)} kg</Text></div>
            </Space>
            );
    }

    genAnalysisDetailOrErrorIndicator(unparsedChartData : string, unparsedDetail: any|null, error: string|null, chartClickHandler: (event: any) => void) : ReactNode {
        if (error && error.length > 0) {
            return (
                <>
                    <Popover content={<Text>{error}</Text>}>
                        {genBooleanDisplay(false)}
                    </Popover>
                    <Popover content={genAnalysisChartPanel(unparsedChartData, chartClickHandler)}>
                        <BarChartOutlined />
                    </Popover>
                </>
                ); 
        }
        else if (unparsedDetail && unparsedDetail.length > 3) {
            const vDetail: any = JSON.parse(unparsedDetail);
            return (
                <>
                    <Popover content={this.genAnalysisDetailPanel(vDetail)}>
                        {genBooleanDisplay(true)}
                    </Popover>
                    <Popover content={genAnalysisChartPanel(unparsedChartData, chartClickHandler)}>
                        <BarChartOutlined />
                    </Popover>
                </>
              );
          }
        return (<></>);
    }
}