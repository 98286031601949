import { CloseSquareTwoTone, CheckCircleTwoTone } from '@ant-design/icons';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import Text from "antd/es/typography/Text";
import { ReactNode } from "react";

export const COLOUR_SUCCESS:    string = "#52c41a";
export const COLOUR_FAILURE:    string = "#ff0000";
export const COLOUR_UNDEFINED:  string = "#b3b3b3";

export enum KilnType {
    CGoOne = 0,
    Retort = 1,
    Terrafix = 2
}

export function genBooleanDisplay(value: boolean): any {
    if (value) {
        return (<CheckCircleTwoTone twoToneColor={COLOUR_SUCCESS} />)
    }
    return (<CloseSquareTwoTone twoToneColor={COLOUR_FAILURE}/>)
}

export function genAnalysisChartPanel(chart_data: string, callback: (event: any) => void) : ReactNode {
    if (!chart_data) { 
        return (<Text type="secondary">No Chart Available</Text>);
    }
    return (
        <LineChart 
            width={650} 
            height={450} 
            data={JSON.parse(chart_data)}
            margin={{ top: 5, right: 5, left: 5, bottom: 25 }}
            onClick={callback}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="ts" angle={-45} textAnchor='end'/>
            <YAxis />
            <Tooltip />
            <Line 
                type="monotone" 
                dataKey="t1" 
                name="temp 1" 
                stroke="#5680ea" 
                unit="°C" 
                strokeWidth={2}
                dot={false}/>
            <Line 
                type="monotone" 
                dataKey="t2" 
                name="temp 2"
                stroke="#e5981b"
                unit="°C"
                strokeWidth={2}
                dot={false}/>
            <Line 
                type="monotone" 
                dataKey="w" 
                name="weight x10" 
                stroke="#c9c9c7" 
                unit=" kg"
                strokeWidth={3}
                dot={false}/>
        </LineChart>
    )
}