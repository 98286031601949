import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Home from "./components/pages/home";
import Login from "./components/pages/login";
import ArchivedKilnLogs from "./components/pages/archivedKilnLogs";
import KilnLogs from "./components/pages/kilnLogs";
import KilnRegistry from "./components/pages/kilnRegistry";
import KilnDetail from "./components/pages/kilnDetail";
import Facilities from "./components/pages/facilities";
import FacilityDetail from "./components/pages/facilityDetail";
import Audit from "./components/pages/capsules";
import UserAdmin from "./components/pages/users";
import Profile from "./components/pages/profile";
import { AbilityContext } from "./permissions";
import ErrorPage from "./components/pages/error";
import useToken from "./useToken";
import { useEffect, useState } from "react";
import { decodeJwt, JWTPayload } from "jose";
import { ExtractSubjectType, MongoAbility, MongoQuery, Subject, SubjectRawRule, createMongoAbility } from "@casl/ability";
import HeaderSection from "./components/Header";
import { ConfigProvider, theme, Switch, Layout} from "antd";
import Text from "antd/es/typography/Text";

const ability : MongoAbility = createMongoAbility();
const { Footer, Content } = Layout;

function router(jwt: JWTPayload, able: MongoAbility, logout: () => void) {
  return createBrowserRouter([
    {
      path: "/",
      element:  <HeaderSection jwt={jwt} logout={logout} />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "home",
          element: <Home jwt={jwt}/>,
        },
        {
          path: "facilities",
          element: <Facilities jwt={jwt} />,
        },
        {
          path: "kiln_logs",
          element: <KilnLogs />,
        },
        {
          path: "archived_kiln_logs",
          element: <ArchivedKilnLogs />,
        },
        {
          path: "kiln_reg",
          element: <KilnRegistry />,
        },
        {
          path: "users",
          element: <UserAdmin />,
        },
        {
          path: "profile",
          element: <Profile jwt={jwt} />,
        },
        {
          path: "audit/review",
          element: <Audit jwt={jwt}/>,
        },
        {
          path: "kiln_detail",
          element: <KilnDetail />,
        },
        {
          path: "facility_detail",
          element: <FacilityDetail />,
        },
      ],
    },
  ])
}

function verifyJWT(token: string|null): JWTPayload|null {
  if (token != null) {
    // TODO clearly we need to move to using a key pair and actually verifying our payload
    // in the meantime we are trusting the JWT locally for UI mutation etc - so you could hack
    // your way to say, seeing the kiln file listing page - however the back end will not serve
    // you any kiln files, as your mutated JWT you used to hack it up, would not pass verification on server
    const pay: JWTPayload = decodeJwt(token!);
    if (pay.exp && pay.exp < Math.floor(Date.now() / 1000)) {
      return null;
    }

    return pay;
  }
  return null;
} 

function App() {

  const { token, setToken } = useToken();
  const [ verifiedTokenPayload, setVerifiedTokenPayload ] = useState<any>();
  const { defaultAlgorithm, darkAlgorithm } = theme;
  
  const get_is_dark_mode = () => {
    const dm : string|null = localStorage.getItem('isDarkMode');
    return dm  !== null && dm === "1";
  }

  const [ isDarkMode, setIsDarkMode ] = useState<boolean>(get_is_dark_mode());

  useEffect(() => {
    if (token != null && token.length > 0) {
      const ver: JWTPayload | null = verifyJWT(token);
      setVerifiedTokenPayload(ver);    
      ability.update(ver?.rules as SubjectRawRule<string, ExtractSubjectType<Subject>, MongoQuery>[]);
    }
  }, [token]);

  const set_is_dark_mode = (isDark: boolean) => {
    localStorage.setItem('isDarkMode', isDark ? "1" : "0");
  }

  

  const onThemeChange = (checked: boolean) => {
    setIsDarkMode(checked);
    set_is_dark_mode(checked);
  };

  function PotentiallyLoggedInApp({loggedIn}: any) {

    if (loggedIn) {
       return (
        <AbilityContext.Provider value={ability}>
          <RouterProvider router={router(verifiedTokenPayload, ability, () => 
              {
                setToken("")
                window.location.href= '/home';
              }
            )} />
        </AbilityContext.Provider>
       );
    }
    else { 
      return (
        <>
          <Content>
              <Login setToken={setToken} />
          </Content>
        </>
      );
    }   
  }

  return (
    <ConfigProvider theme={{
      algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm,
    }}>
      <Layout>
        
        <PotentiallyLoggedInApp loggedIn={!(verifiedTokenPayload == null || verifiedTokenPayload.sub == null || verifiedTokenPayload.role == null)} />
        
        <Footer>
          <Text type="secondary">Dark Mode</Text><Switch checked={isDarkMode} onChange={onThemeChange}/>
        </Footer>
      </Layout>
    </ConfigProvider>
  );
}

export default App;
