import { useEffect, useState } from "react";
import { Input, Select, notification } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import { DatePicker, Space, Tooltip, Button } from 'antd';
import dayjs from 'dayjs';
import Text from "antd/es/typography/Text";
import { KilnTypeUIRenderer, genRendererMap } from "../kiln_types/factory";
import { KilnType } from "../kiln_types/common";
import { KilnLogTable } from "../widgets/datasets/kiln_log_table";

const { RangePicker } = DatePicker;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare type EventValue<Dayjs> = dayjs.Dayjs | null;
declare type RangeValue<Dayjs> = [EventValue<Dayjs>, EventValue<Dayjs>] | null;

function triggerAnalysis(kilnlog_id: string, callback: () => void, manualQuench: string|null) {
  const requestOptions = {
    method: 'POST',
    headers: { 
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + sessionStorage.getItem('token')
    },
    body: ""
  };
  if (manualQuench !== null) {
    requestOptions.body = JSON.stringify({manual_quench: manualQuench});
  }
  fetch(`/api/kiln/analyse/${kilnlog_id}`, requestOptions)
    .then(response => {
      if (!response.ok) {
        notification.error({
          message: 'Error on analysis run.',
          description: 'Please contact support to ensure this is investigated.',
          duration: 4,
          placement: "top",
        });
      }
      callback();
    })
    .catch(err => {
      console.log("error on analysis request " + err);
      callback();
    });
}

function KilnLogs(props : any) {

  const [logs, setLogs] = useState<any[]>([]);
  const [facilities, setFacilities] = useState<any[]>([]);
  
  const defaultPreDate: dayjs.Dayjs = dayjs().subtract(2, 'month');

  const kilnTypeRenderMap: Map<KilnType, KilnTypeUIRenderer> = genRendererMap();
  
  const [searchParams, setSearchParams] = useState<any>({
    facility_id: 0,
    kilnid: "",
    uploadfrom: defaultPreDate,
    uploadto: dayjs(),
    logfrom: defaultPreDate,
    logto: dayjs(),
    trigger: 0,
  });

  useEffect(() => {
    const executeSearch = async () => {
      await fetch('/api/kiln/logs?' + new URLSearchParams({
        uf: searchParams.uploadfrom.toISOString().split('T')[0],
        ut: searchParams.uploadto.add(1, 'day').toISOString().split('T')[0],
        id: searchParams.kilnid,
        fi: searchParams.facility_id,
        lf: searchParams.logfrom.toISOString().split('T')[0],
        lt: searchParams.logto.add(1, 'day').toISOString().split('T')[0],
      }), {
        headers: {
          'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
          },
      }).then(response => {
        if (response.ok) {
          return response.json();
        }
        notification.error({
          message: 'Error retrieving kiln logs.',
          description: 'An unknown error occurred retrieving the kiln logs. Please try again later.',
          duration: 4,
          placement: "top",
        });
        return null;
      })
      .then(data => {
        if (data !== null) {
          setLogs(data.kilnlogs);
          setFacilities(data.facilities);
        }
      })
      .catch(err => {
        console.log("error " + err);
      });
    }

    executeSearch();
  }, [searchParams])

  function KilnSearchInputs(props: any) {

    const [searchBuffer, setSearchBuffer] = useState<any>({
      facility_id: searchParams.facility_id,
      kilnid: searchParams.kilnid,
      uploadfrom: searchParams.uploadfrom,
      uploadto: searchParams.uploadto,
      logfrom: searchParams.logfrom,
      logto: searchParams.logto,
    });

    const onChangeUploadDateRange = (value: RangeValue<dayjs.Dayjs>) => {
      setSearchBuffer({
        ...searchBuffer,
        'uploadfrom': value?.[0],
        'uploadto': value?.[1]
      });
    };

    const onChangeBurnDateRange = (value: RangeValue<dayjs.Dayjs>) => {
      setSearchBuffer({
        ...searchBuffer,
        'logfrom': value?.[0],
        'logto': value?.[1]
      });
    };
  
    const onChange = (e: any) => {
      setSearchBuffer({
        ...searchBuffer,
        'kilnid': e.target.value,
      });
    };
  
    const updateSearchParams = () => {
      setSearchParams({
        ...searchParams,
        'uploadfrom': searchBuffer.uploadfrom,
        'uploadto': searchBuffer.uploadto,
        'logfrom': searchBuffer.logfrom,
        'logto': searchBuffer.logto,
        'kilnid': searchBuffer.kilnid,
        'facility_id': searchBuffer.facility_id,
      });
    }

    const handleFacilityChange = (value: string) => {
      setSearchBuffer({
        ...searchBuffer,
        'facility_id': value,
      });
    }

    return (
      <>
        <Space>
          <Text>Facility:</Text>
          <Tooltip title="Restrict to one facility.">
            <Select
              style={{ width: 200 }}
              onChange={handleFacilityChange}
              options={facilities}
              value={searchBuffer.facility_id}
            />
          </Tooltip>
          <Text>Kiln:</Text>
          <Tooltip title="Enter a specific kiln id to see only results for that kiln.">
            <Input style={{ width: 150 }} defaultValue={searchBuffer.kilnid} onChange={onChange} onPressEnter={updateSearchParams}/>
          </Tooltip>
          <Text>Upload Date:</Text>
          <Tooltip title="Upload date range to search (inclusive)">
            <RangePicker allowClear={false} allowEmpty={[false, false]} defaultValue={[searchBuffer.uploadfrom, searchBuffer.uploadto]} onChange={onChangeUploadDateRange} />
          </Tooltip>
          <Text>Log Date:</Text>
          <Tooltip title="Log date range to search (inclusive)">
            <RangePicker allowClear={false} allowEmpty={[false, false]} defaultValue={[searchBuffer.logfrom, searchBuffer.logto]} onChange={onChangeBurnDateRange} />
          </Tooltip>
          <Tooltip title="Search">
            <Button type="primary" shape="circle" icon={<SearchOutlined />} onClick={updateSearchParams} />
          </Tooltip>
        </Space>
      </>
    )
  }

  // a hack to cause a change to the search data, so a re-search is triggered to populate
  // the displayed table.
  function refresh(): void {
    setSearchParams({
      ...searchParams, 
      trigger: searchParams.trigger + 1});
  }

  function removeLocalLog(id: string) : void {
    setLogs(logs.filter(l => l.id !== id));
  }

  return (
    <div className="wrapper">
    <h1>Kiln Logs</h1>
    <Space direction="vertical">
      <KilnSearchInputs/>
      <KilnLogTable 
        logs={logs} 
        rendererMap={kilnTypeRenderMap} 
        refreshCallback={refresh} 
        removeLocalLogFunction={removeLocalLog} 
        triggerAnalysisFunction={triggerAnalysis} />
    </Space>
  </div>
  );
}

export default KilnLogs;