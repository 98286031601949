import { Button, GetProp, notification, Popover, Space, Table, Upload, UploadFile, UploadProps } from "antd";
import { DatabaseOutlined, UploadOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react";
import { genRendererMap, KilnTypeUIRenderer } from "../kiln_types/factory";
import { KilnType } from "../kiln_types/common";
import dayjs from "dayjs";
import Text from "antd/es/typography/Text";
import { genGpsInRangeDisplay, genGPSPopoverContentDisplay, largeValPrettify, s3KeyToFilename } from "../common";
import { ColumnsType } from "antd/es/table";
import Title from "antd/es/typography/Title";


type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

function genActualDataSampleView(data: Array<any>) {
  
  const cols : ColumnsType<any> = [
    {
      title: 'Date',
      width: 60,
      dataIndex: 'd',
      key: 'd',
      fixed: 'left',
    },
    {
      title: 'Time',
      width: 40,
      dataIndex: 't',
      key: 't',
      fixed: 'left',
    },
    {
      title: 'Temp 1',
      width: 40,
      dataIndex: 't1',
      key: 't1',
      fixed: 'left',
    },
    {
      title: 'Temp 2',
      width: 40,
      dataIndex: 't2',
      key: 't2',
      fixed: 'left',
    },
    {
      title: 'Weight',
      width: 40,
      dataIndex: 'w',
      key: 'w',
      fixed: 'left',
    },
  ];
  return (
    <Table 
      dataSource={data} 
      columns={cols}
      pagination={false}
      scroll={{ x: 450 }}
      size="small"
      />
  )
}

function genDataSample(data: string) {
  if (!data) {
    return (<Text type="secondary">No Sample Data</Text>);
  }

  const parsed_data: any = JSON.parse(data);  
  return (
    <Popover content={genActualDataSampleView(parsed_data)}><DatabaseOutlined /></Popover>
  );
}

function AuditSummary(props : any) {
  const { data, nothing } = props;

  if (nothing) {
    return (
      <>
      <Text strong>Summary: </Text><Text>No validated analysed burns available to be audited! </Text>
      </>
    );
  }
  return (
    <>
      <Text strong>Summary: </Text><Text>{data.count} logs, capturing </Text><Text type="success">{largeValPrettify(data.carbon_sum, 1)} kg</Text><Text> of carbon.</Text>
    </>
  );
}

function AuditReviewPanel(props: any) {
  const { refreshCallback, hidden, logs } = props;

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploading, setUploading] = useState(false);

  if (hidden) {
    return (<></>);
  }

  const handleUpload = () => {
    const formData = new FormData();
    formData.append('file', fileList[0] as FileType);

    logs.forEach((log : any) => {
      formData.append('analysis_ids', log.analysis_id);
    });

    setUploading(true);
    fetch('/api/capsule/signoff', {
      method: 'POST',
      headers: { 
        'Authorization': 'Bearer ' + sessionStorage.getItem('token')
      },
      body: formData,
    })
      .then((res) => {
        if (res.ok) {
          setFileList([]);
          notification.success({
            message: 'Audit signoff',
            description: 'Signoff successfully recorded.',
            duration: 4,
            placement: "top",
          });
        }
        else {
          notification.error({
            message: 'Audit signoff',
            description: 'Error recording signoff. Please contact us if this persists.',
            duration: 4,
            placement: "top",
          });  
        }
      })
      .catch(() => {
        notification.error({
          message: 'Audit signoff',
          description: 'Error recording signoff. Please contact us if this persists.',
          duration: 4,
          placement: "top",
        });
      })
      .finally(() => {
        setUploading(false);
        refreshCallback();
      });
  };

  const uploadProps: UploadProps = {
    accept: "png, jpg, jpeg, pdf",
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([file]);

      return false;
    },
    fileList,
  };

  return (
    <Space size="large" direction="horizontal" >
      <Upload {...uploadProps}>
        <Button icon={<UploadOutlined />}>Choose Audit Statement</Button>
      </Upload>
      <Button
        type="primary"
        onClick={handleUpload}
        disabled={fileList.length === 0}
        loading={uploading} >
        {uploading ? 'Processing' : 'Signoff'}
      </Button>
    </Space>
  );
}

function AuditReviewTable(props : any) { 
  const { logs, rendererMap} = props;

  const cols : ColumnsType<any> = [
    {
      title: 'Facility',
      width: 60,
      dataIndex: 'facility_name',
      key: 'facility_name',
      fixed: 'left',
    },
    {
      title: 'Kiln ID',
      width: 60,
      dataIndex: 'kiln_id',
      key: 'kiln_id',
      fixed: 'left',
    },
    {
      title: 'Log Date',
      width: 40,
      dataIndex: 'logts',
      key: 'logts',
      render: (_: any, record: any) => (
        <>{dayjs(record.logts).format('DD-MMM-YYYY')}</>
      ),
    },
    {
      title: 'Filename',
      width: 40,
      dataIndex: 's3key',
      key: 's3key',
      render: (_: any, record: any) => (
        <>{s3KeyToFilename(record.s3key)}</>
      ),
    },
    {
      title: 'Sample Data',
      width: 50,
      dataIndex: 'data_sample',
      align: 'center',
      key: 'data_sample',
      render: (_: any, record: any) => genDataSample(record.data_sample),
    },
    {
      title: 'GPS Valid',
      dataIndex: 'gps_in_range',
      key: 'gps_in_range',
      align: 'center',
      width: 40,
      render: (_: any, record: any) => {
        return (
          <Popover content={genGPSPopoverContentDisplay(record.latitude, record.longitude)}>{genGpsInRangeDisplay(record.gps_in_range)}</Popover>
        ) 
      },
    },
    {
      title: 'Analysis',
      dataIndex: 'analysis_detail',
      key: 'analysis_detail',
      align: 'center',
      width: 40,
      render: (_: any, record: any) => {
        const vType: KilnType = record.type as number;
        const renderer: KilnTypeUIRenderer|undefined = rendererMap.get(vType);
        if (renderer)
          return renderer?.genAnalysisDetailOrErrorIndicator(record.chart_data, record.analysis_detail, null, (event: any) => {});
        else
          return (<Text type="secondary">None</Text>);
      },
    },
    {
      title: 'CO2 Removed',
      dataIndex: 'analysis_detail',
      key: 'analysis_detail',
      align: 'center',
      width: 40,
      render: (_: any, record: any) => {
        if (!record.analysis_detail) {
          return (<Text type="secondary">N/A</Text>);
        }
        const vDetail: any = JSON.parse(record.analysis_detail);
        return (
          <Text>{largeValPrettify(vDetail.co2_removed, 1)} kg</Text>
        )
      },
    },
  ];

  return (
    <>
      <Table 
        dataSource={logs} 
        columns={cols}
        pagination={false}
        scroll={{ x: 1200 }}
        size="small"
        bordered />
    </>
  );
}

function Audit(props : any) {

  const [logs, setLogs] = useState<any[]>([]);
  const [summary, setSummary] = useState<any>({});

  const kilnTypeRenderMap: Map<KilnType, KilnTypeUIRenderer> = genRendererMap();
  
  const [searchParams, setSearchParams] = useState<any>({
    trigger: 0,
  });

  function triggerRefresh() {
    setSearchParams({
      trigger: searchParams.trigger + 1,
    });
  }

  useEffect(() => {
    const executeSearch = async () => {
      await fetch('/api/capsule/logs', {
        headers: {
          'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
          },
      }).then(response => {
        if (response.ok) {
          return response.json();
        }
        notification.error({
          message: 'Error retrieving logs for audit.',
          description: 'An unknown error occurred retrieving the auditable logs. Please try again later.',
          duration: 4,
          placement: "top",
        });
        return null;
      })
      .then(data => {
        if (data !== null) {
          setLogs(data.logs);
          setSummary(data.summary);
        }
      })
      .catch(err => {
        console.log("error " + err);
      });
    }

    executeSearch();
  }, [searchParams])

  const noData = logs.length === 0;

  return (
    <div className="wrapper">
      <Space size="middle" direction="vertical" >
        <Title level={3}>Review Logs</Title>
        <AuditReviewPanel refreshCallback={triggerRefresh} hidden={noData} logs={logs} />
        <AuditSummary 
          data={summary} nothing={noData} />
        <AuditReviewTable 
          logs={logs} 
          rendererMap={kilnTypeRenderMap} />
      </Space>  
  </div>
  );
}

export default Audit;