import { useState } from "react";
import { saveAs } from "file-saver";
import { Modal, Popover, notification } from "antd";
import { AreaChartOutlined, CloudDownloadOutlined, DeleteOutlined } from '@ant-design/icons';
import { Space, Tooltip, Table} from 'antd';
import dayjs from 'dayjs';
import { ColumnsType } from "antd/es/table";
import Text from "antd/es/typography/Text";
import { genGPSPopoverContentDisplay, genGpsInRangeDisplay, s3KeyToFilename, sizeDisplay } from "../../common";
import { KilnTypeUIRenderer } from "../../kiln_types/factory";
import { KilnType } from "../../kiln_types/common";
import { Can } from "../../../permissions";


export function KilnLogTable({
  archive_mode=false, 
  title="Kiln Logs",
  ...props
} : any) {
    const [archiveTargetId, setArchiveTargetId] = useState<string>("");
    const [showKilnLogArchiveConfirm, setShowKilnLogArchiveConfirm] = useState<boolean>(false);
    const [showManualQuenchConfirm, setShowManualQuenchConfirm] = useState<boolean>(false);
    const [manualQuenchData, setManualQuenchData] = useState<any>({});
    const [analysisProcessing, setAnalysisProcessing] = useState<boolean>(false);
  
    const analysisColumns : ColumnsType<any> = archive_mode ? [] : [
      {
        title: 'GPS Valid',
        dataIndex: 'gps_in_range',
        key: 'gps_in_range',
        align: 'center',
        width: 60,
        render: (_: any, record: any) => {
          return (
            <Popover content={genGPSPopoverContentDisplay(record.latitude, record.longitude)}>{genGpsInRangeDisplay(record.gps_in_range)}</Popover>
          ) 
        },
      },
      {
        title: 'Analysis',
        dataIndex: 'analysis_detail',
        key: 'analysis_detail',
        align: 'center',
        width: 60,
        render: (_: any, record: any) => {
          const vType: KilnType = record.type as number;
          const renderer: KilnTypeUIRenderer|undefined = props.rendererMap.get(vType);
          if (renderer)
            return renderer?.genAnalysisDetailOrErrorIndicator(record.chart_data, record.analysis_detail, record.error, (event: any) => handleChartClick(event, record.id, record.s3key));
          else
            return (<></>);
        },
      }
    ];

    const detailsColumns : ColumnsType<any> = [
      {
        title: 'Log Date',
        width: 105,
        dataIndex: 'logts',
        key: 'logts',
        render: (_: any, record: any) => (
          <>{dayjs(record.logts).format('DD-MMM-YYYY')}</>
        ),
      },
      {
        title: 'Upload Date',
        width: 105,
        dataIndex: 'uploadts',
        key: 'uploadts',
        render: (_: any, record: any) => (
          <>{dayjs(record.uploadts).format('DD-MMM-YYYY')}</>
        ),
      }];
      if (archive_mode) {
        detailsColumns.push({
          title: 'Archive Date',
          width: 105,
          dataIndex: 'archival_ts',
          key: 'archival_ts',
          render: (_: any, record: any) => (
            <>{dayjs(record.archival_ts).format('DD-MMM-YYYY')}</>
          ),
        }); 
      }

      detailsColumns.push({
        title: 'Filename',
        width: 60,
        dataIndex: 's3key',
        key: 's3key',
        render: (_: any, record: any) => (
          <>{s3KeyToFilename(record.s3key)}</>
        ),
      },
      {
        title: 'Size',
        width: 40,
        dataIndex: 'file_size',
        key: 'file_size',
        render: (_: any, record: any) => (
          <>{sizeDisplay(record.file_size ? record.file_size : 0)}</>
        ),
      });

    const cols : ColumnsType<any> = [
      {
        title: 'Kiln ID',
        width: 60,
        dataIndex: 'kiln_id',
        key: 'kiln_id',
        fixed: 'left',
        render: (_: any, record: any) => (
            <a href={`/kiln_detail?id=${record.kiln_id}`}>{record.kiln_id}</a>
          ),
      },
      {
        title: 'Type',
        width: 60,
        dataIndex: 'type',
        key: 'type',
        render: (_: any, record: any) => {
          const renderer : KilnTypeUIRenderer = props.rendererMap.get(record.type as KilnType)
          return renderer.genKilnTypeTag();
        }
      },
      {
        title: 'File Details',
        children: detailsColumns,
      },
    ];

    if (!archive_mode) {
      cols.push({
        title: 'Analysis Results',
        children: analysisColumns,
      },);
    }

    cols.push({
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      width: 60,
      render: (_:any, record: any) => {
        if (archive_mode) {
          return (
            <Space align="center">
              <Can I='download' a='kilnlog'>
                <Tooltip title="Download kiln log">
                  <CloudDownloadOutlined onClick={() => getSingleKilnLog(record.s3key)}/>
                </Tooltip>
              </Can>
              <Can I='delete' a='kilnlog'>
                <Tooltip title="Archive kiln log">
                  <DeleteOutlined onClick={() => showDeleteConfirm(record.id)}/>
                </Tooltip>
              </Can>
            </Space>
          )
        }
        else {
          return (
            <Space align="center">
              <Can I='download' a='kilnlog'>
                <Tooltip title="Download kiln log">
                  <CloudDownloadOutlined onClick={() => getSingleKilnLog(record.s3key)}/>
                </Tooltip>
              </Can>
              <Can I='create' a='analysis'>
                <Tooltip title="Trigger analysis for this kiln log">
                  <AreaChartOutlined onClick={() => props.triggerAnalysisFunction(record.id, props.refreshCallback)}/>
                </Tooltip>
              </Can>
              <Can I='delete' a='kilnlog'>
                <Tooltip title="Archive kiln log">
                  <DeleteOutlined onClick={() => showDeleteConfirm(record.id)}/>
                </Tooltip>
              </Can>
            </Space>
          )
        }
      }
    });
    
    function handleChartClick(event: any, id: number, s3key: string) : void {
      if (!archive_mode && event.activeLabel) {
        setManualQuenchData({
          time: event.activeLabel,
          id: id,
          s3key: s3key,
        });
        setShowManualQuenchConfirm(true);
      }
    }

    function confirmManualQuench() {
      setAnalysisProcessing(true);
      props.triggerAnalysisFunction(manualQuenchData.id, props.refreshCallback, manualQuenchData.time);
      setAnalysisProcessing(false);
      setShowManualQuenchConfirm(false);
    }

    function showDeleteConfirm(kilnlog_id: string) {
      setArchiveTargetId(kilnlog_id);
      setShowKilnLogArchiveConfirm(true);
    }
    
    function confirmKilnLogArchive() {    
      if (triggerArchival(archiveTargetId)) {
        props.removeLocalLogFunction(archiveTargetId);
      }
  
      setShowKilnLogArchiveConfirm(false);
      setArchiveTargetId("");
    }

    function getSingleKilnLog(s3key: string) {
      const requestOptions = {
        method: 'GET',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + sessionStorage.getItem('token')
        },
      };
      fetch(`/api/kiln/single/${s3key}`, requestOptions)
        .then(response => response.blob())
        .then(blob => saveAs(blob, s3key))
        .catch(err => {
          console.log("error on file save or download attempt " + err);
        });
    }

    function triggerArchival(kilnlog_id: string) : boolean {
      const requestOptions = {
        method: 'DELETE',
        headers: { 
          'Authorization': 'Bearer ' + sessionStorage.getItem('token')
        },
      };
      
      fetch(`/api/kiln/log/${kilnlog_id}/archive?` + new URLSearchParams({
        mode: archive_mode ? "0" : "1",
        }), requestOptions).then(response => {
        if (!response.ok) {
          notification.error({
            message: 'Error on attempting to archive kiln log.',
            description: 'Please contact support to ensure this is investigated.',
            duration: 4,
            placement: "top",
          });
          return false;
        }
      })
      .catch(err => {
        console.log("error on log archive request " + err);
        return false;
      });
      return true;
    }

    const archival_text = archive_mode ? "Unarchive" : "Archive";
  
    return (
      <>
        <Table 
          dataSource={props.logs} 
          columns={cols} 
          scroll={{ x: 1200 }}
          size="small"
          bordered />
        <Modal
          title="Confirm?"
          open={showKilnLogArchiveConfirm}
          onOk={confirmKilnLogArchive}
          onCancel={() => setShowKilnLogArchiveConfirm(false)}>
            <Text>Really {archival_text} this log?</Text>
        </Modal>
        <Modal
          title="Confirm?"
          open={showManualQuenchConfirm}
          onOk={confirmManualQuench}
          onCancel={() => setShowManualQuenchConfirm(false)}
          confirmLoading={analysisProcessing} >
            <Text>Are you sure you wish to re-analyse this log (file: {s3KeyToFilename(manualQuenchData.s3key)}) using a manually identified quench point at {manualQuenchData.time}?</Text>
        </Modal>
      </>
    )
  }
