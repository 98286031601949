import { Button, Modal, Popover, Space, notification} from "antd";
import { useEffect, useState } from "react";
import Title from "antd/es/typography/Title";
import Text from "antd/es/typography/Text";
import { useSearchParams } from "react-router-dom";
import { Can } from "../../permissions";
import { KilnLogSummarySelectable } from "../widgets/datasets/kiln_log_summaries";
import { PhotoList } from "../widgets/photos/photo_list"
import { InfoCircleOutlined } from '@ant-design/icons';



/** Basic Details Panel */
function FacilityBasicInfoPanel(props: any) {

  const mnemonic = props.mnemonic;
  const name = props.name;
  
  return (
    <>
    <Space size="small" direction="vertical">
      <Title level={2}>Facility Detail</Title>
      <Space size="small" direction="horizontal">
        <Text>Mnemonic: </Text><Text type="secondary">{mnemonic}</Text>
        <Text>Name: </Text><Text type="secondary">{name}</Text>
      </Space>
    </Space>
    </>
  )
}





function FacilityDetail(props : any) {

  const [facility, setFacility] = useState<any>({});
  const [availablePhotos, setAvailablePhotos] = useState<any[]>([]);
  const [kilnLogs, setKilnLogs] = useState<any[]>([]);

  // modal show controls
  const [showCapsuleCreateModal, setShowCapsuleCreateModal] = useState<boolean>(false);

  const [validatedLogsChosen, setValidatedLogsChosen] = useState<Array<number>>([]);
  const [selectedPhotoIds, setSelectedPhotoIds] = useState<Array<number>>([]);

  //const [trigger, setTrigger] = useState<number>(0);
  const [searchParams] = useSearchParams();
  
  const input_facility_id : string|null = searchParams.get('id');

  useEffect(() => {
    const executeSearch = async () => {
      await fetch(`/api/facility/${input_facility_id}/detail`, {
        headers: {
          'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
          },
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        notification.error({
          message: 'Server error querying facility details.',
          description: 'Please try again later.',
          duration: 4,
          placement: "top",
        });
        return null;
      })
      .then(data => {
        if (data != null) {
          setFacility(data.facility);
          setKilnLogs(data.logs);
          setAvailablePhotos(data.photos);
        }
        else {
          notification.error({
            message: 'Error querying facility details.',
            description: 'No details available.',
            duration: 4,
            placement: "top",
          });
        }
      })
      .catch(err => {
        console.log("error " + err);
        notification.error({
          message: 'Error querying facility details.',
          description: 'Please try again later.',
          duration: 4,
          placement: "top",
        });
      });
      
    }
    executeSearch();
  }, [input_facility_id])

  async function deletePhoto(unique_id: string) {
    await fetch(`/api/photo/${unique_id}/delete`, {
      method: 'DELETE',
      headers: {
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        },
      })
      .then(response => {
        if (response.ok) {
          console.log('yay! deleted');
          setAvailablePhotos(availablePhotos.filter(function(p) {
            return p.unique_id !== unique_id;
          }));
        }
        else if (response.status === 403)
        {
          notification.error({
            message: 'Deletion forbidden',
            description: 'Either you lack permission, or this photo cannot be deleted.',
            duration: 4,
            placement: "top",
          });
        }
        else {
          notification.error({
            message: 'Error deleting photo.',
            description: 'Please try again later.',
            duration: 4,
            placement: "top",
          });  
        }
      }).catch(err => {
        console.log(`error ${JSON.stringify(err)}`);
        notification.error({
          message: 'Error deleting photo.',
          description: 'Please try again later.',
          duration: 4,
          placement: "top",
        });
      });
  }

  return (
  <div className="wrapper">
    <Space size="small" direction="vertical">
      <FacilityBasicInfoPanel 
        mnemonic={facility.mnemonic} 
        name={facility.name}/>
      <KilnLogSummarySelectable 
        logs={kilnLogs} 
        title="Analysed Logs" 
        buttonPane={(
          <Can I='create' a='capsule'> 
            <Space size="small" direction="horizontal">
              <Button type="primary" onClick={() => setShowCapsuleCreateModal(true)}>Create Capsule</Button>
              <Popover content={(<Text>Select burn logs and images you wish to include in any capsule you create.</Text>)}><InfoCircleOutlined/></Popover>
            </Space>
          </Can>
        )}
        setSelectedRows={setValidatedLogsChosen} />
      <PhotoList
        title="Photos Available for Use"
        photos={availablePhotos} 
        deletePhoto={deletePhoto}
        setSelectedRows={setSelectedPhotoIds} />
    </Space>
    <Modal
      title="Confirm Capsule Creation"
        open={showCapsuleCreateModal}
        onOk={() => setShowCapsuleCreateModal(false)}
        confirmLoading={false}
        //okButtonProps={{disabled: !calibrationInputsValid}}
        onCancel={() => setShowCapsuleCreateModal(false)}>
          <Text>{JSON.stringify(validatedLogsChosen)} {JSON.stringify(selectedPhotoIds)}</Text>
    </Modal>
  </div>
  );
}

export default FacilityDetail;