import { KilnTypeUIRenderer, UnknownKilnTypeRenderer } from "./factory";
import { Tag } from "antd";
import { KilnType } from "./common";
import { ReactNode } from "react";
 
export class RetortUIRenderer implements KilnTypeUIRenderer {

    readonly delegate : KilnTypeUIRenderer = new UnknownKilnTypeRenderer();

    genKilnTypeTag() : ReactNode {
        return (<Tag color='blue' key={KilnType.Retort}>Retort</Tag>);
    }

    genKilnValidationWidget(detail: any, valid: number|null) : ReactNode {
        return this.delegate.genKilnValidationWidget(detail, valid);
    }

    genAnalysisDetailPanel(json: any) : ReactNode {
        return this.delegate.genAnalysisDetailPanel(json);
    }

    genAnalysisDetailOrErrorIndicator(unparsedChartData : string, unparsedDetail: any|null, error: string|null, chartClickHandler: (event: any) => void) : ReactNode {
        return this.delegate.genAnalysisDetailOrErrorIndicator(unparsedChartData, unparsedDetail, error, chartClickHandler);
    }
}