import Table, { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import { genGPSPopoverContentDisplay, genGpsInRangeDisplay } from "../../common";
import { Popover, Space } from "antd";
import { KilnType } from "../../kiln_types/common";
import { KilnTypeUIRenderer, genRendererMap } from "../../kiln_types/factory";
import Title from "antd/es/typography/Title";

export function KilnLogSummarySelectable(
    {
        showKilnId=true, 
        title="Kiln Logs",
        buttonPane=(<></>), 
        ...props
    } : any) {

    const kilnTypeRendererMap: Map<KilnType, KilnTypeUIRenderer> = genRendererMap();

    const cols : ColumnsType<any> = [
        {
            title: 'Kiln ID',
            dataIndex: 'kiln_id',
            key: 'kiln_id',
            width: 80,
            hidden: !showKilnId,
        },
        {
            title: 'Log Date',
            dataIndex: 'logts',
            key: 'logts',
            width: 105,
            render: (_: any, record: any) => (
                <>{dayjs(record.logts).format('DD-MMM-YYYY')}</>
            ),
        },
        {
            title: 'GPS Valid',
            dataIndex: 'gps_in_range',
            key: 'gps_in_range',
            align: 'center',
            width: 60,
            render: (_: any, record: any) => (
                <Popover content={genGPSPopoverContentDisplay(record.latitude, record.longitude)}>{genGpsInRangeDisplay(record.gps_in_range)}</Popover>
            ),
        },
        {
            title: 'Analysis',
            dataIndex: 'detail',
            key: 'detail',
            align: 'center',
            width: 60,
            render: (_: any, record: any) => {
                const vType: KilnType = record.type as number;
                const renderer: KilnTypeUIRenderer|undefined = kilnTypeRendererMap.get(vType);
                if (renderer)
                    return renderer?.genAnalysisDetailOrErrorIndicator(record.chart_data, record.detail, record.error, (event: any) => {});
                else
                    return (<></>);
            },
        },
    ];
    
    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
            props.setSelectedRows(selectedRowKeys);
        },
        getCheckboxProps: (record: any) => ({
            disabled: false,
            name: record.name,
        }),
    };
    
    return (
        <Space direction="vertical" size="small">
            <Space size="small">
                <Title level={3}>{title}</Title>
                {buttonPane}
            </Space>
            <Table 
                dataSource={props.logs} 
                rowKey='analysis_id'
                rowSelection={{
                    type: 'checkbox',
                    ...rowSelection,
                }}
                columns={cols} 
                scroll={{ x: 400 }} 
                size="small"
                bordered />
        </Space>
    )
}